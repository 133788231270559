import { render, staticRenderFns } from "./cedis.vue?vue&type=template&id=ebed0194"
import script from "./cedis.vue?vue&type=script&lang=js"
export * from "./cedis.vue?vue&type=script&lang=js"
import style0 from "./cedis.vue?vue&type=style&index=0&id=ebed0194&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports